<template>
   <component :is="component" ></component>
</template>

<script>

import SimulationType from "./Wizzard/SimulationType.vue";
import SimulationOperations from "./Wizzard/SimulationOperations.vue";
import SimulationInfos from "./Wizzard/SimulationInfos.vue";
import SimulationPlanning from "./Wizzard/SimulationPlanning.vue";
import SimulationDocuments from "./Wizzard/SimulationDocuments.vue";
import FinalStep from "./Wizzard/FinalStep.vue";
import {mapGetters, mapMutations} from "vuex";
import SimulationImportMass from "@/views/Simulations/Wizzard/SimulationImportMass.vue";

export default {
  name: "SimulationWizzard",
  components: {
    SimulationType,
    SimulationOperations,
    SimulationInfos,
    SimulationPlanning,
    SimulationDocuments,
    SimulationImportMass,
    FinalStep
  },
  props: ['currentStep', 'selectedSteps'],
  data(){
    let stepsNames = [
        'SimulationType',
        'SimulationInfos',
        'SimulationPlanning',
        'SimulationOperations',
        'SimulationImportMass',
        'SimulationDocuments',
        'FinalStep'
      ]
    return {
      mappings : stepsNames,
      stepsNames
    }
  },
  computed:{
    ...mapGetters('simulation', {simulationType: 'getSimulationType'}),
    component(){
      this.filterSelectedSteps();
      this.setComponentName(this.mappings[this.currentStep]);
      return this.mappings[this.currentStep];
    }
  },
  watch: {
    simulationType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filterSelectedSteps();
      }
    }
  },
  methods: {
    ...mapMutations('simulation', ['setComponentName']),
    filterSelectedSteps(){
      this.mappings = this.stepsNames.filter((_, index) =>  this.selectedSteps.includes(index))
    }
  }
}

</script>
