<template>
  <div id="SimulationType">
    <div class="simulator-step_header">
      <h3 class="simulator-step_title">{{ $t("add_the_new_simulation") }}</h3>
      <p class="simulator-step_description">
        {{ $t("choose_simulation_type") }}
      </p>
    </div>
    <div class="row" v-if="!isEdit">
      <div
        v-for="(simulationType, index) in simulationTypes"
        :key="index"
        :class="simulationTypeClass(index)"
      >
        <div class="giga-simulation_type" v-if="showMassImportSimulationType(index)">
          <input
            type="radio"
            :id="'radio-group-' + index"
            :value="simulationType.value"
            v-model="$v.simulationForm.simulationType.$model"
            name="radio-options"
            @change="setType($event)"
          />
          <label class="simulation-type_content">
            <div class="simulation-type_icon">
              <div class="type-icon_content">
                <img
                  v-if="
                    $v.simulationForm.simulationType.$model ==
                      simulationType.value
                  "
                  :src="simulationType.activeIcon"
                  alt=""
                />
                <img v-else :src="simulationType.icon" alt="" />
                <span>{{ $t(simulationType.label) }}</span>
              </div>
            </div>
            <div class="simulation_type_description">
              <p>{{ $t(simulationType.text) }}</p>
            </div>
            <span class="check-icon">
              <svg
                width="13"
                height="9"
                viewBox="0 0 13 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.70711 3.29289C1.31658 2.90237 0.683417 2.90237 0.292893 3.29289C-0.0976311 3.68342 -0.0976311 4.31658 0.292893 4.70711L1.70711 3.29289ZM5 8L4.29289 8.70711C4.68342 9.09763 5.31658 9.09763 5.70711 8.70711L5 8ZM12.7071 1.70711C13.0976 1.31658 13.0976 0.683417 12.7071 0.292893C12.3166 -0.0976311 11.6834 -0.0976311 11.2929 0.292893L12.7071 1.70711ZM0.292893 4.70711L4.29289 8.70711L5.70711 7.29289L1.70711 3.29289L0.292893 4.70711ZM5.70711 8.70711L12.7071 1.70711L11.2929 0.292893L4.29289 7.29289L5.70711 8.70711Z"
                  fill="white"
                />
              </svg>
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { simulation } from "../../../store/simulation.module";
import { helpers } from "@/shared/helper";
import { constants } from "@/shared/constants";

export default {
  name: "SimulationType",
  data() {
    return {
      edit: false,
      selectDocuments: [],
      simulationForm: {
        name: "",
        deliveryDate: "",
        simulationType: "SIMPLE"
      },
      isComplexSimulation: false,
      SIMULATION_TYPE: constants.SIMULATION_TYPE,
    };
  },
  mounted() {
    this.$v.simulationForm.simulationType.$model = this.simulationType;
  },
  validations: {
    simulationForm: {
      name: {
        required
      },
      deliveryDate: {
        required
      },
      simulationType: {
        required
      }
    }
  },
  computed: {
    ...mapGetters("simulation", {
      simulation: "getSimulation",
      simulationTypes: "getSimulationTypes",
      simulationType: "getSimulationType"
    }),
    isEdit() {
      return (
        this.simulation.id !== null &&
        this.simulation.id !== undefined &&
        this.simulation.id !== ""
      );
    },
    isAnArtisan(){
      return helpers.isAnArtisan();
    },
  },
  methods: {
    ...mapMutations("simulation", { setSimulationType: "setSimulationType" }),
    ...mapActions("simulation", [
      "persistSimulationAction",
      "resetSimulationAction"
    ]),
    persist() {
      let id = null;
      if (this.simulation.id) {
        id = this.simulation.id;
      }
      const data = {
        id: id,
        // name: this.simulationForm.name,
        // deliveryDate: this.simulationForm.deliveryDate,
        type: this.simulationForm.simulationType
      };
      this.isComplexSimulation = data.type === "COMPLEX";
      this.persistSimulationAction({ simulation: data });
    },
    setType(event) {
      this.setSimulationType(event.target.value);
    },
    showMassImportSimulationType(index) {
      if (index === 0 || index === 1) {
        return true;
      }
      else if (this.isAnArtisan) {
        return true;
      }
      return false;
    },
    simulationTypeClass(index) {
      return {
        'col-lg-12 import-mass-style': index === 2,
        'col-lg-6': index === 0 || index === 1,
      }
    }
  }
};
</script>

<style>
  .import-mass-style{
      margin-top: 30px;
  }
</style>
